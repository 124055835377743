import React, { useState } from 'react'
import Link from 'next/link'
import Image from 'next/image'

const BopupModal = () => {
  const [modalOpen, setModalOpen] = useState(true)

  const CTAPage = '/shop'

  return (
    <div
      className={`transform  ${
        modalOpen
          ? 'translate-y-[220px] sm:translate-y-[240px] md:translate-y-[190px] lg:translate-y-[215px]'
          : 'translate-y-[50px] sm:translate-y-[100px] md:translate-y-[30px]'
      }
       cursor-pointer z-[2] transition-transform duration-500 ease-in-out fixed bottom-0 left-0 w-full px-3 pt-3 pb-0 lg:mb-[-60px] leading-3 flex flex-col  h-[300px] xs:h-[280px] sm:h-[300px] md:h-[260px] lg:h-[360px] bg-white border-yolk-500 border-solid border-t-3 lg:border-t-4 border-x-0 border-b-0`}
      onClick={() => setModalOpen(!modalOpen)}
    >
      <button className="absolute top-0 left-0 mt-2 border-none">
        <Image
          src={
            modalOpen
              ? '/img/rebrand/arrow_down.svg'
              : '/img/rebrand/arrow_up.svg'
          }
          alt="Arrow Good Ranchers"
          width={12}
          height={15}
        />
      </button>
      <button className="absolute top-0 right-0 mt-2 border-none">
        <Image
          src={
            modalOpen
              ? '/img/rebrand/arrow_down.svg'
              : '/img/rebrand/arrow_up.svg'
          }
          alt="Arrow Good Ranchers"
          width={12}
          height={15}
        />
      </button>
      <div className="flex flex-col align-middle p-0">
        <h1 className="text-center font-ringside-ssm-medium font-[700] whitespace-pre-line md:whitespace-normal lg:my-2 w-[95%] m-auto text-beef-500 text-[20px] md:text-[27px] lg:text-[30px] leading-[21px] md:leading-6 lg:leading-[30px] uppercase ">
          FREE WINGS FOR A YEAR — $150 Value
        </h1>
        <div
          className={`flex flex-col  text-center align-middle justify-center items-center`}
        >
          <div className="flex flex-col text-start align-top items-center gap-4 sm:gap-0  md:mt-5 xs:mt-8 2xs:mt-[22px]">
            <h2 className=" text-beef-500 text-center  md:text-[18px]  text-coal-900 text-[13px] leading-2 lg:text-[20px] xs:-mt-[20px] lg:mt-2">
              Subscribe & use code FREEWINGS to claim your free jumbo wings.
            </h2>
            <p className="text-coal-900  leading-5 w-[90%] md:w-[600px] lg:leading-6 text-center font-ringside-ssm-book font-[400]  text-[13px] md:text-[14px] lg:text-[16px] xs:-mt-[20px] -my-4 lg:my-2 md:mb-0 ">
              Our chicken is all{' '}
              <span className="font-[900]">No Antibiotics Ever</span> certified,
              so you can enjoy every bite knowing it’s safe, trustworthy, and
              good for you and your family.
            </p>
            <div className="md:my-4 xs:my-1 text-center ">
              <Link href={CTAPage}>
                <a href={CTAPage}>
                  <button className="btn-primary-tw border-0 text-center">
                    Get My Wings
                  </button>
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BopupModal
