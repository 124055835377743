import AsFeaturedIn from '@components/AsFeaturedIn'
import Features from '@components/Features'
import ProductListHome from '@components/ProductListHome'
import { Product } from 'types/products'
import FAQ from '@components/Accordion/FAQ'
import React, { useEffect, useState, useContext } from 'react'
import StaticReviews from '@components/Reviews/StaticReviews'
import BlogSnippet from '@components/Card/ArticleCard/BlogSnippet'
import Layout from '@components/Layout'
import { bcAPIFetch } from 'utils'
import { getActiveBoxList } from '@utils/products'
import Script from 'next/script'
import ChefBeauHome from '@components/ChefBeauHome'
import GenericHero from '@components/Hero/GenericHero'
import { BlogPost } from 'types/blog'
import _ from 'lodash'
import TeamPenske from '@components/User/TeamPenske'
import BopupModal from '@components/Modal/Bopup'
import { GlobalContext } from 'contexts'
import HowItWorks from '@components/Card/HowItWorks'
import MarchMeatnessHero from '@components/Hero/MarchMeatness'

export async function getStaticProps() {
  const {
    BIGCOMMERCE_STORE_API_URL: API_URL,
    BIGCOMMERCE_STORE_API_TOKEN: API_TOKEN,
  } = process.env
  const recentPosts = await bcAPIFetch(
    `${API_URL}/v2/blog/posts?limit=3&is_published=true`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Auth-Token': API_TOKEN,
      },
    }
  )
    .then((res) => res.json())
    .catch((err) => {
      console.error(err)
    })

  const filteredProducts = await getActiveBoxList()

  return {
    props: {
      products: filteredProducts,
      recentPosts,
    },
  }
}

export default function Home({
  products,
  recentPosts,
}: {
  products: Product[]
  recentPosts: BlogPost[]
}) {
  const {
    state: { drawerOpen },
  } = useContext(GlobalContext)

  const [pageVersion, setPageVersion] = useState<string>('')

  /**
   * experiement ids are in the form YYYY-MM-DD-#
   */
  const experimentId = '2024-03-24-0'
  const controlVersion = 'control'
  const experimentVersion = 'A'

  /**
   * Gets at random one of the two version IDs that are being A/B tested.
   *
   * @returns string
   */
  const versionRandomizer = (): string => {
    const weight = 0.8
    const randomValue = Math.random()

    const num = randomValue < weight ? 1 : 0

    if (num === 0) {
      return controlVersion
    } else {
      return experimentVersion
    }
  }

  useEffect(() => {
    const versionID = sessionStorage.getItem('pageVersion')
    let pageVersion
    if (
      _.isUndefined(versionID) ||
      _.isNull(versionID) ||
      _.isEmpty(versionID)
    ) {
      const versionRandom = versionRandomizer()
      sessionStorage.setItem('pageVersion', versionRandom)
      setPageVersion(versionRandom)
      pageVersion = versionRandom
    } else {
      setPageVersion(versionID)
      pageVersion = versionID
    }

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.innerHTML = `var experiment_id = '${experimentId}'; var page_version='${pageVersion}';`

    document.head.appendChild(script)

    return () => {
      document.head.removeChild(script)
    }
  }, [])

  const handleEverflowLoad = () => {
    window.EF.configure({
      tld: 'goodranchers.com',
    })
    window.EF.click({
      offer_id: window.EF.urlParameter('oid'),
      affiliate_id: window.EF.urlParameter('affid'),
      sub1: window.EF.urlParameter('sub1'),
      sub2: window.EF.urlParameter('sub2'),
      sub3: window.EF.urlParameter('sub3'),
      sub4: window.EF.urlParameter('sub4'),
      sub5: window.EF.urlParameter('sub5'),
      uid: window.EF.urlParameter('uid'),
      source_id: window.EF.urlParameter('source_id'),
      transaction_id: window.EF.urlParameter('_ef_transaction_id'),
    })
  }

  const heroAbTest = () => {
    if (pageVersion === controlVersion) {
      return <GenericHero />
    } else if (pageVersion === experimentVersion) {
      return <MarchMeatnessHero />
    } else {
      return null
    }
  }

  return (
    <>
      <Script
        type="text/javascript"
        src="https://static.ordergroove.com/8beb972a6fd211ec9204725e278b534c/main.js"
      />
      <Script
        src="https://www.gr0zpw2st.com/scripts/sdk/everflow.js"
        type="text/javascript"
        onLoad={handleEverflowLoad}
      />
      <body>
        <div className="heroContainer">{heroAbTest()}</div>
        <HowItWorks />
        <div className="relative top-[20px] lg:top-[50px]">
          <ProductListHome products={products} />
        </div>
        <div className="bg-beef-500 bg-marbling-texture-featured">
          <Features />
          <AsFeaturedIn />
        </div>
        <div className="flex flex-col md:mt-[-160px] lg:mt-[-140px] z-0 bg-cream-300">
          <FAQ />
        </div>
        <div className="bg-cream-300 mx-auto">
          <ChefBeauHome />
        </div>
        <div className="flex flex-col pt-[40px] bg-cream-300">
          <StaticReviews />
        </div>
        <div className="flex flex-col pt-[20px] pb-[80px] bg-cream-300">
          <BlogSnippet
            posts={recentPosts}
            bgColor="bg-white"
            headline="top articles"
            homePageBgColor="bg-cream-300"
          />
        </div>
        <div className=" bg-cream-300 pb-[80px]">
          <TeamPenske />
        </div>
        <div className={`${drawerOpen ? 'hidden' : 'flex'} flex-col`}>
          <BopupModal />
        </div>
      </body>
    </>
  )
}

Home.Layout = Layout
