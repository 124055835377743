import Link from 'next/link'
import { useWindowSize } from 'hooks'
import { UserContext } from 'contexts/user-context'
import { useEffect, useContext } from 'react'
import * as _ from 'lodash'
import { sha256 } from 'js-sha256'

declare const window: any

const GenericHero = () => {
  const windowSize = useWindowSize()
  const pageWidth = windowSize?.width || 0
  var deviceType = pageWidth > 768 ? 'desktop' : 'mobile'

  const {
    state: { user },
  } = useContext(UserContext)

  let emailEncrypted = sha256(user ? user.email : '')

  useEffect(() => {
    if (user && user.email) {
      window.criteo_q = window.criteo_q || []
      window.criteo_q.push(
        {
          event: 'setAccount',
          account: process.env.NEXT_PUBLIC_CRITEO_ACCOUNT,
        },
        {
          event: 'setEmail',
          email: `${user.email ? emailEncrypted : ''}`,
          hash_method: 'sha256',
        },
        { event: 'setSiteType', type: deviceType },
        { event: 'viewHome' }
      )
    } else {
      window.criteo_q = window.criteo_q || []
      window.criteo_q.push(
        {
          event: 'setAccount',
          account: process.env.NEXT_PUBLIC_CRITEO_ACCOUNT,
        },
        {
          event: 'setEmail',
          email: '',
          hash_method: '',
        },
        { event: 'setSiteType', type: deviceType },
        { event: 'viewHome' }
      )
    }
  }, [])

  const CTAPage = '/shop'

  return (
    <div className="relative flex justify-center items-center  bg-cream-300">
      <img
        className="sm:hidden h-[500px] w-[638px] object-cover"
        src="/img/hero/Meat-First-Chicken-Wing-Hero-Mobile.webp"
        alt=""
      />
      <img
        className="hidden sm:flex lg:w-full  h-[650px] lg:object-cover"
        src="/img/hero/Chicken-Wing-Hero-Desktop.webp"
        alt=""
      />


      <div className="absolute z-10 text-center w-full lg:-mt-[10px] xs:-mt-[90px]">
        <h1 className="title-hero-beef-tw text-[40px] w-[90%] md:w-[50%] m-auto text-center lg:w-[600px] content-center lg:text-[72px] font-[415] leading-[30px] lg:leading-[54px] text-beef-500">
          AMERICAN MEAT & SEAFOOD YOU CAN TRUST{' '}
        </h1>
        <p className="my-4 lg:my-8 leading-6 sm:leading-[30px] text-center w-[80%] w-[500px] sm:w-[500px] xs:w-[300px] m-auto font-ringside-ssm-book text-sm sm:text-[16px] md:text-lg text-grayscale-coal-900">
          Avoid unknown vaccines in store-bought meat. Subscribe during our
          mRNA-Free Sale and enjoy 24lbs of free, jumbo wings in your first year
          with code <span className="font-bold">FREEWINGS</span>.
        </p>
        <Link href={CTAPage}>
          <div className="mt-8">
            <a href={CTAPage}>
              <button className="btn-primary-tw border-0 w-[200px] xs:-mt-[20px] lg:mt-[10px] lg:w-[200px] lg:py-[22px]">
                CLAIM $150 OFFER
              </button>
            </a>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default GenericHero
