import { useEffect, useState } from 'react'
import ModalVideo from 'react-modal-video'

const ChefBeauHome = () => {
  const [isOpen, setOpen] = useState(false)
  const thresholdWidth = 1000
  const [windowWidth, setWindowWidth] = useState(0)
  const isWideScreen = windowWidth > thresholdWidth

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    if (typeof window !== 'undefined') {
      setWindowWidth(window.innerWidth)
      window.addEventListener('resize', handleResize)
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [])
  return (
    <>
      {isOpen ? (
        <div>
          <ModalVideo
            channel="youtube"
            isOpen={isOpen}
            autoplay="1"
            showinfo="0"
            ratio={isWideScreen ? '16:9' : '3:4'}
            videoId="_S9sdaenDFQ"
            onClose={() => setOpen(false)}
          />
        </div>
      ) : null}
      <div>
        <div className="text-center pt-[36px]">
          <div className="text-night-800 font-knockout-71 leading-[30px] lg:leading-[45px] font-[400] text-[40px] lg:text-[60px] mb-8 lg:mb-8 uppercase pt-6 px-1">
            Iron chef Approved
          </div>
        </div>
        <div
          style={{
            boxShadow: 'inset 0 0 0 1000px rgba(0, 0, 0, 0.5)',
          }}
          className="bg-[url('/img/rebrand/chef-beau.jpg')] bg-cover bg-center lg:rounded-xl max-w-[1024px] h-[300px] lg:h-auto xl:max-w-[1260px] m-auto py-8 px-6 lg:py-[96px] lg:px-16"
        >
          <div>
            <div className="text-start">
              <h3 className="font-knockout-71 justify-start flex text-[20px] max-w-[250px] lg:max-w-[624px] lg:text-[72px] text-start lg:text-start leading-4 lg:leading-[60px] text-white uppercase mt-0 mb-7 lg:mb-[46px] font-[400] lg:m-0">
                “If you want to be a culinary hero, this is what you have to
                purchase.” <br /> Chef Beau
              </h3>
              <span
                className="btn-underline-tw text-white cursor-pointer"
                onClick={() => setOpen(true)}
              >
                Watch the video
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ChefBeauHome
