import Link from 'next/link'
import { useWindowSize } from 'hooks'
import { UserContext } from 'contexts/user-context'
import { useEffect, useContext } from 'react'
import * as _ from 'lodash'
import { sha256 } from 'js-sha256'

declare const window: any

const MarchMeatnessHero = () => {
  const windowSize = useWindowSize()
  const pageWidth = windowSize?.width || 0
  var deviceType = pageWidth > 768 ? 'desktop' : 'mobile'

  const {
    state: { user },
  } = useContext(UserContext)

  let emailEncrypted = sha256(user ? user.email : '')

  useEffect(() => {
    if (user && user.email) {
      window.criteo_q = window.criteo_q || []
      window.criteo_q.push(
        {
          event: 'setAccount',
          account: process.env.NEXT_PUBLIC_CRITEO_ACCOUNT,
        },
        {
          event: 'setEmail',
          email: `${user.email ? emailEncrypted : ''}`,
          hash_method: 'sha256',
        },
        { event: 'setSiteType', type: deviceType },
        { event: 'viewHome' }
      )
    } else {
      window.criteo_q = window.criteo_q || []
      window.criteo_q.push(
        {
          event: 'setAccount',
          account: process.env.NEXT_PUBLIC_CRITEO_ACCOUNT,
        },
        {
          event: 'setEmail',
          email: '',
          hash_method: '',
        },
        { event: 'setSiteType', type: deviceType },
        { event: 'viewHome' }
      )
    }
  }, [])

  const CTAPage = '/shop'

  return (
    <div>
      <div className="relative flex justify-center items-center bg-cream-300">
        <img
          className="sm:hidden h-[500px] w-[638px] object-cover"
          src="/img/hero/March-Meatness-Hero-Mobile.webp"
          alt=""
        />
        <img
          className="hidden sm:flex lg:w-full  h-[650px] lg:object-cover object-bottom"
          src="/img/hero/March-Meatness-Hero-Desktop.webp"
          alt=""
        />
        <div className="absolute top-[250px] text-center w-full lg:-mt-[200px] xs:-mt-[230px]">
          <img
            src="img/march-meatness/MarchMeatness-Logo.svg"
            alt=""
            className="max-w-full w-[296px] lg:w-[400px] max-h-full xs:mb-[5px] lg:mb-[20px]"
          />
          <h1 className="title-hero-beef-tw text-[40px] xs:text-[30px] m-auto text-center lg:w-[600px] content-center lg:text-[72px] font-[415] leading-[30px] lg:leading-[54px] text-white">
            Cuts Worthy of a Championship{' '}
          </h1>
          <p className="mt-2 -mb-[10px] lg:my-8 leading-6 sm:leading-[30px] text-center lg:w-[600px] xs:w-[300px] m-auto font-ringside-ssm-book text-sm sm:text-[16px] xs:text-[12px] md:text-lg text-white">
            We're taking March Meatness way beyond March with free, jumbo
            chicken wings for a year! Subscribe today with code{' '}
            <span className="font-bold">FREEWINGS</span> to claim your 24lbs of
            free chicken wings.
          </p>
          <Link href={CTAPage}>
            <div className="mt-8">
              <a href={CTAPage}>
                <button className="btn-primary-tw border-0 w-[200px] xs:-mt-[20px] lg:mt-[10px] lg:w-[200px] lg:py-[22px]">
                  CLAIM $150 OFFER
                </button>
              </a>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default MarchMeatnessHero
