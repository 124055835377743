/* eslint-disable @next/next/no-img-element */
import useWindowSize from 'hooks/useWindowSize'
import React from 'react'

const TeamPenske = () => {
  const { width: screenSize } = useWindowSize()
  return (
    <div
      className={`overflow-hidden lg:rounded-xl m-auto lg:mx-3 xl:m-auto rounded-xl max-w-[300px] lg:max-w-[1200px] flex flex-col lg:flex-row bg-white  `}
    >
      <div className="h-full flex">
        {screenSize < 1024 ? (
          <img
            src="/img/Team_Penske_mobile.png"
            alt=""
            className="w-[344px]  object-cover lg:hidden"
          />
        ) : (
          <div className="w-[550px]">
            <img
              src="/img/Team_Penske.png"
              alt=""
              className="h-full w-[520px]  object-cover"
            />
          </div>
        )}
      </div>

      <div className="flex flex-col lg:mx-auto justify-center items-center xs:items-start ">
        <div className="lg:text-left lg:items-center ">
          <div className="text-[28px] lg:text-[40px] 2xs:leading-6 lg:leading-9 font-knockout-71  xs:mt-5  uppercase text-night-800 lg:text-left xs:text-left ml-5">
            Proud Sponsor of <br /> Team Penske
          </div>
        </div>
        <div className="font-ringside-ssm-light leading-[20px] w-[93%] lg:text-[15px] xs:text-[12px] lg:text-left 2xs:text-left text-night-800 xs:mt-5 mb-4 m-auto pl-2 lg:pl-1">
          Since 2023 we’ve been the official meat sponsor of Team Penske. You
          can find us on the #3 car driven by Scott McLaughlin, soon to be
          champion of the IndyCar Series.
        </div>
        <div className="lg:w-[50%] hidden lg:block flex-row"></div>
      </div>
    </div>
  )
}

export default TeamPenske
